import {Component} from "react";
import {Prompt} from "react-router-dom";
import ReactModal from "react-modal";
import moment from "moment";
import {EASEBUZZ_PAYMENT_GATEWAY_SOURCE, EASEBUZZ_PAYMENT_GATEWAY_ENV, EASEBUZZ_DUROLT_MERCHANT_KEY, SUCCESS} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_GUEST_USER} from "../assets/constants/BrowserStorageKeys";
import {APP_GREEN_COLOR} from "../assets/constants/Colors";
import {MdError} from "react-icons/md";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {isGuestUserAuthenticated} from "../lib/AuthUtils";
import {postPaymentSaveTransaction} from "../lib/BackendUtils";
import {storeSecureItemInSpecifiedStorage, getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import {withTranslation} from "react-i18next";

class PaymentDetailsPage extends Component {
    constructor (props) {
        super (props)

        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const domain = domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null;
        const lockerBankId = domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null;
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);

        this.state = {
            isLoading: true,
            loadingMessage: null,
            awaitingPostPaymentSaveTransactionResponse: false,
            showTransactionErrorModal: false,
            domain: domain,
            lockerBankId: lockerBankId,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            currentReferenceId: this.props.history.location.state?.currentReferenceId,
            userAction: null,
            remainingTimeToMakeSuccessfulPayment: this.props.history.location.state?.remainingTimeToMakeSuccessfulPayment,
            paymentGatewayAccessKey: this.props.history.location.state?.paymentGatewayAccessKey,
            redirectionPath: this.props.history.location.state?.redirectionPath,
            transactionErrorMessage: null,
            remainingTimeInSecondsToRedirect: 10
        }
    }

    componentDidMount = () => {
        if (this.state.isUserAuthenticated) {
            window.onPaymentResponse = this.onPaymentResponse;
            this.constructAndAppendEasebuzzPaymentGatewayScriptElement();
            this.setState({
                isLoading: false
            });
        }
    }

    constructAndAppendEasebuzzPaymentGatewayScriptElement = () => {
        const easebuzzPaymentGatewayScriptElement = document.createElement("script");
        easebuzzPaymentGatewayScriptElement.src = EASEBUZZ_PAYMENT_GATEWAY_SOURCE;
        easebuzzPaymentGatewayScriptElement.id = "easebuzz-payment-gateway";

        const easebuzzPaymentGatewayContainerElement = document.getElementById("easebuzz-payment-gateway-container");
        easebuzzPaymentGatewayContainerElement.appendChild(easebuzzPaymentGatewayScriptElement);
        easebuzzPaymentGatewayScriptElement.onload = () => {
            this.constructAndAppendEasebuzzPaymentGatewayInvokerScriptElement();
        }
    }

    constructAndAppendEasebuzzPaymentGatewayInvokerScriptElement = () => {
        const easebuzzPaymentGatewayInvokerScriptElement = document.createElement("script");
        const partialTextContent1 = `var easebuzzCheckout = new EasebuzzCheckout("${EASEBUZZ_DUROLT_MERCHANT_KEY}", "${EASEBUZZ_PAYMENT_GATEWAY_ENV}");`;
        const partialTextContent2 = `var options = {access_key: "${this.state.paymentGatewayAccessKey}", onResponse: (response) => {window.onPaymentResponse(response)}, theme: "${APP_GREEN_COLOR}"};`;
        const partialTextContent3 = `easebuzzCheckout.initiatePayment(options);`;
        easebuzzPaymentGatewayInvokerScriptElement.textContent = partialTextContent1 + partialTextContent2 + partialTextContent3;
        easebuzzPaymentGatewayInvokerScriptElement.id = "easebuzz-payment-gateway-invoker";

        const easebuzzPaymentGatewayContainerElement = document.getElementById("easebuzz-payment-gateway-container");
        easebuzzPaymentGatewayContainerElement.appendChild(easebuzzPaymentGatewayInvokerScriptElement);
        easebuzzPaymentGatewayInvokerScriptElement.onload = this.preventReloadAndNavigationWhenTheUserIsMakingPayment();
    }

    preventReloadAndNavigationWhenTheUserIsMakingPayment = () => {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (e) => {
        const message = this.props.t('warning_msg_do_not_reload_or_close_tab');
        e.returnValue = message;
        return message;
    }

    onPaymentResponse = (paymentGatewayResponse) => {
        if (paymentGatewayResponse) {
            this.savePostPaymentTransactionDetailsAfterPaymentGatewayResponse(paymentGatewayResponse);
        }
    }
    
    payButtonHandlerToBookNewLocker = async () => {
        this.setState({
            awaitingLockerSizeAvailabilityAndPrePaymentRegistrationResponse: true
        });
        await this.makePrePaymentRegistration();
    }

    savePostPaymentTransactionDetailsAfterPaymentGatewayResponse = async (paymentGatewayResponse) => {
        document.getElementById("easebuzz-payment-gateway-invoker").remove();
        let loadingMessage = "";
        let transactionErrorMessage = "";

        if (paymentGatewayResponse.status === SUCCESS) {
            loadingMessage = this.props.t('transaction_successful');
        }
        this.setState({
            awaitingPostPaymentSaveTransactionResponse: true,
            loadingMessage: loadingMessage
        });

        const additionalHeaders = {'Authorization': `token ${this.state.userInfo.authToken}`};
        const body = {
            reference_id: this.state.currentReferenceId,
            payment_status: paymentGatewayResponse.status,
            payment_amount: paymentGatewayResponse.amount,
            payment_gateway_transaction_id: paymentGatewayResponse.txnid,
            payment_mode: paymentGatewayResponse.mode,
            // payment_bank_ref_num: paymentGatewayResponse.bank_ref_num,
            payment_transaction_time: moment().valueOf(),
            payment_gateway_response: JSON.stringify(paymentGatewayResponse)
        };
        let response = await postPaymentSaveTransaction(this.state.domain, additionalHeaders, body);

        if (paymentGatewayResponse.status === SUCCESS) {
            if (response.status === SUCCESS) {
                if ((response.data?.status === SUCCESS) && (response.data?.data?.locker_unit)) {
                    let userInfo = this.state.userInfo;
                    userInfo.assignedLockersCount = userInfo.assignedLockersCount + 1;

                    this.setState({
                        awaitingLockerSizeAvailabilityAndPrePaymentRegistrationResponse: false,
                        awaitingPostPaymentSaveTransactionResponse: false
                    }, () => {
                        storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_GUEST_USER, userInfo);
                        // document.getElementById("easebuzz-payment-gateway-invoker").remove();
                        window.removeEventListener('beforeunload', this.handleBeforeUnload);
                        this.props.history.push({
                            pathname: "/payment-completion",
                            state: {
                                transactionDetails: paymentGatewayResponse,
                                assignedLockerInfo: response.data.data,
                                assignedLockerSize: response.data.data.locker_size,
                                assignedLockerUnit: response.data.data.locker_unit,
                                bookingDuration: response.data.data.duration,
                                bookingStartTime: response.data.data.booking_start_time,
                                bookingEndTime: response.data.data.booking_end_time
                            }
                        });
                    });
                }
            }
            else {
                transactionErrorMessage = this.props.t('error_msg_transaction_successful_but_unable_to_verify');

                this.setState({
                    redirectionPath: "/my-lockers",
                    transactionErrorMessage: transactionErrorMessage,
                    showTransactionErrorModal: true
                }, () => {
                    this.countdownTimerToRedirectTheUser(this.state.redirectionPath);
                });
            }
        }
        else {
            if (paymentGatewayResponse.status === "userCancelled") {
                transactionErrorMessage = this.props.t('error_msg_transaction_cancelled_try_again');
            }
            else {
                transactionErrorMessage = this.props.t('error_msg_transaction_failed_try_again');
            }

            this.setState({
                transactionErrorMessage: transactionErrorMessage,
                showTransactionErrorModal: true
            }, () => {
                this.countdownTimerToRedirectTheUser(this.state.redirectionPath);
            });
        }
    }

    redirectionHandler = () => {
        this.setState({
            showTransactionErrorModal: false
        }, () => {
            window.removeEventListener('beforeunload', this.handleBeforeUnload);
            this.props.history.push({
                pathname: this.state.redirectionPath
            });
        });
    }

    countdownTimerToRedirectTheUser = () => {
        let remainingTimeInSecondsToRedirect = this.state.remainingTimeInSecondsToRedirect;

        let countdownTimerInterval = setInterval(() => {
            if (this.state.showTransactionErrorModal && (this.state.remainingTimeInSecondsToRedirect > 0)) {
                --remainingTimeInSecondsToRedirect;

                this.setState({
                    remainingTimeInSecondsToRedirect: remainingTimeInSecondsToRedirect
                });
            }
            else {
                clearInterval(countdownTimerInterval);
                this.redirectionHandler();
            }
        }, 1000);
    }

    componentWillUnmount = () => {}

    render () {
        return (
            <div className="payment-details-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">{this.props.t('page_title_payment')}</div>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.awaitingPostPaymentSaveTransactionResponse && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON(APP_GREEN_COLOR)}</div>
                            {this.state.loadingMessage && (
                                <div className="loading-message">{this.state.loadingMessage}</div>
                            )}
                        </div>
                    )}
                    {!this.state.awaitingPostPaymentSaveTransactionResponse && (
                        <div className="main">
                            <div className="react-router-dom-prompt">
                                <Prompt when={this.state.awaitingLockerSizeAvailabilityAndPrePaymentRegistrationResponse} />
                            </div>
                            <div id="easebuzz-payment-gateway-container"></div>
                        </div>
                    )}
                </div>
                <ReactModal
                    isOpen={this.state.showTransactionErrorModal}
                    onRequestClose={() => this.setState({showTransactionErrorModal: false})}
                    className="react-modal transaction-error-modal"
                    overlayClassName="react-modal-overlay transaction-error-modal-overlay"
                >
                    <div className="transaction-error-modal-content">
                        <div className="message-and-button-container">
                            <div className="icon-container">
                                <MdError className="icon-md-error" />
                            </div>
                            <div className="error-message">{this.state.transactionErrorMessage}</div>
                            <div className="redirection-message">{this.props.t('redirecting_in_time_in_seconds', {timeInSeconds: this.state.remainingTimeInSecondsToRedirect})}</div>
                            <div className="ok-button" onClick={() => this.redirectionHandler()}>{this.props.t('button_ok')}</div>
                        </div>
                    </div>
                </ReactModal>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaymentDetailsPage);