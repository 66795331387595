import {Component} from "react";
import moment from "moment";
import ReactModal from "react-modal";
import QrReader from "react-qr-reader";
import SlideButton from "react-slide-button";
import {LUGGAGE, SUCCESS, ERROR, REDIRECT, HH_mm_ss, TOAST_SUCCESS, TOAST_WARN, TOAST_ERROR, OPEN_LOCKER, BOOKING_TYPE_ADDITIONAL} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, LS_GUEST_USER} from "../assets/constants/BrowserStorageKeys";
import {GiLockers} from "react-icons/gi";
import {IoTime, IoTimer} from "react-icons/io5";
import {MdAccountCircle, MdChevronRight, MdLocationOn, MdMoreTime} from "react-icons/md";
import {RiDoorLockBoxFill} from "react-icons/ri";
import {ROTATING_LOADING_ICON, CHEVRONS_RIGHT} from "../assets/constants/Icons";
import {isGuestUserAuthenticated, getTenantAssignmentLockerBankConfigurationSettings} from "../lib/AuthUtils";
import {getDomainAndLockerBankInfoFromScannedQRCode, convertMillisecondsToSpecifiedTimeFormat} from "../lib/Utils";
import {getAssignedLockers, openLocker, prePaymentRegistration} from "../lib/BackendUtils";
import {storeSecureItemInSpecifiedStorage, getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";
import UserModal from "../components/UserModal";
import Footer from "../components/Footer";

class MyLockersPage extends Component {
    constructor (props) {
        super (props)

        const tenantAssignmentAndLockerBankSettings = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS);
        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const domain = domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null;
        const lockerBankId = domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null;
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);

        this.state = {
            isLoading: true,
            loadingMessage: null,
            showUserModal: false,
            showPaymentConfirmationModal: false,
            showQRCodeReader: false,
            showErrorMessageAndRetryButtonIfUnableToGetAssignedLockers: false,
            stopCountdownTimersAndClearIntervalWhenTrue: false,
            isOpeningLockerUnit: false,
            domain: domain,
            lockerBankId: lockerBankId,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            tenantAssignmentAndLockerBankSettings: tenantAssignmentAndLockerBankSettings,
            assignedLockersList: [],
            selectedLocker: null,
            overstayAmount: null
        }
    }

    componentDidMount = () => {
        if ((this.state.domain !== null) && (this.state.lockerBankId !== null) && (this.state.isUserAuthenticated)) {
            localStorage.setItem("userAction", OPEN_LOCKER);
            this.getAssignedLockersAndStartCountdownTimers();
        }
    }

    userModalHandler = () => {
        this.setState({
            showUserModal: true
        });
    }

    logoutHandler = () => {
        localStorage.removeItem(LS_GUEST_USER);
        sessionStorage.clear();

        this.setState({
            stopCountdownTimersAndClearIntervalWhenTrue: true
        }, () => {
            this.props.history.push({
                pathname: "/",
                state: {
                    message: this.props.t('success_msg_successfully_logged_out'),
                    messageType: TOAST_SUCCESS
                }
            });
        });
    }

    tryAgainButtonHandler = () => {
        this.setState({
            isLoading: true,
            showErrorMessageAndRetryButtonIfUnableToGetAssignedLockers: false,
            stopCountdownTimersAndClearIntervalWhenTrue: true
        }, () => {
            this.getAssignedLockersAndStartCountdownTimers();
        })
    }

    getAssignedLockersAndStartCountdownTimers = async () => {
        const additionalHeaders = {Authorization: `Token ${this.state.userInfo.authToken}`};
        const response = await getAssignedLockers(this.state.domain, additionalHeaders);

        if (response.status === SUCCESS) {
            this.setState({
                assignedLockersList: response.data.data.assigned_lockers.length > 0 ? response.data.data.assigned_lockers : []
            }, () => {
                let userInfo = this.state.userInfo;
                userInfo.assignedLockersCount = this.state.assignedLockersList.length;
                storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_GUEST_USER, userInfo);

                if (this.state.assignedLockersList.length > 0) {
                    this.countdownTimersForRemainingDuration();
                }

                this.setState({
                    isLoading: false
                });
            });
        }
        else if (response.status === ERROR) {
            this.setState({
                isLoading: false,
                showErrorMessageAndRetryButtonIfUnableToGetAssignedLockers: true
            }, () => {
                ShowToast(this.props.t(response.message), TOAST_WARN);
            });
        }
        else if (response.status === REDIRECT) {
            this.setState({
                isLoading: false
            }, () => {
                localStorage.removeItem(LS_GUEST_USER);
                sessionStorage.clear();
                this.props.history.push({
                    pathname: "/"
                });
                window.location.reload();
            });
        }
    }

    countdownTimersForRemainingDuration = () => {
        let assignedLockersList = this.state.assignedLockersList;
        let currentTimeInMilliseconds = moment().valueOf();

        let countdownTimerInterval = setInterval(() => {
            if (this.state.stopCountdownTimersAndClearIntervalWhenTrue) {
                clearInterval(countdownTimerInterval);
            }
            else {
                currentTimeInMilliseconds = moment().valueOf();

                assignedLockersList.forEach((locker, index) => {
                    locker.isOverstay = (currentTimeInMilliseconds < locker.booking_end_time_grace) ? false : true;
                    locker.hasGracePeriodStarted = (currentTimeInMilliseconds < locker.booking_end_time) ? false : true;

                    if (locker.isOverstay) {
                        locker.remainingTimeInMilliseconds = 0;
                        locker.overstayDurationInMilliseconds = currentTimeInMilliseconds - locker.booking_end_time;
                    }
                    else if (locker.hasGracePeriodStarted) {
                        locker.remainingTimeInMilliseconds = locker.booking_end_time_grace - currentTimeInMilliseconds;
                    }
                    else {
                        locker.remainingTimeInMilliseconds = locker.booking_end_time - currentTimeInMilliseconds;
                    }
                });

                this.setState({
                    assignedLockersList: assignedLockersList
                });
            }
        }, 1000);
    }

    openSelectedLocker = async () => {
        this.setState({
            isOpeningLockerUnit: true
        });

        const additionalHeaders = {Authorization: `token ${this.state.userInfo.authToken}`};
        const body = {locker_bank: this.state.selectedLocker.locker_bank, locker_unit: this.state.selectedLocker.locker_unit};
        const response = await openLocker(this.state.domain, additionalHeaders, body);

        if (response.status === SUCCESS) {
            const assignedLockerInfo = {
                lockerBankId: response.data.data.locker_bank,
                lockerBankName: response.data.data.bank_name,
                lockerBankLocation: response.data.data.locker_bank_location,
                lockerUnit: response.data.data.locker_unit.unit_id,
                lockerSize: response.data.data.locker_unit.size,
                bookingStartTimeInMilliseconds: response.data.data.booking_start_time,
                bookingEndTimeInMilliseconds: response.data.data.booking_end_time,
                bookingEndTimeWithGracePeriodInMilliseconds: response.data.data.booking_end_time_grace
            };
            
            this.setState({
                isOpeningLockerUnit: false,
                stopCountdownTimersAndClearIntervalWhenTrue: true
            }, () => {
                this.props.history.push({
                    pathname: "/locker-opened",
                    state: {
                        assignedLockerInfo: assignedLockerInfo
                    }
                });
            });
        }
        else if (response.status === ERROR) {
            this.setState({
                isOpeningLockerUnit: false
            }, () => {
                ShowToast(this.props.t(response.message), TOAST_ERROR);
                this.setState({
                    selectedLocker: null
                });
            });
        }
        else if (response.status === REDIRECT) {
            this.setState({
                isOpeningLockerUnit: false,
                stopCountdownTimersAndClearIntervalWhenTrue: true
            }, () => {
                this.props.history.push({
                    pathname: "/",
                    state: {
                        message: this.props.t(response.message),
                        messageType: TOAST_ERROR
                    }
                });
            });
        }
    }

    overstayPayButtonHandler = () => {
        let overstayDuration = Math.ceil(this.state.selectedLocker.overstayDurationInMilliseconds / 3600000);
        let priceOfSelectedLocker = this.state.tenantAssignmentAndLockerBankSettings.assignmentPricing[this.state.selectedLocker.locker_size].price;

        this.setState({
            overstayAmount: overstayDuration * parseFloat(priceOfSelectedLocker),
            showPaymentConfirmationModal: true
        });
    }

    makePrePaymentRegistration = async () => {
        this.setState({
            isLoading: true,
            loadingMessage: this.props.t('initiating_transaction_please_wait'),
            showPaymentConfirmationModal: false
        });

        const additionalHeaders = {'Authorization': `token ${this.state.userInfo.authToken}`};
        const body = {
            ref_id: this.state.selectedLocker.ref_id,
            use_case: LUGGAGE,
            booking_type: BOOKING_TYPE_ADDITIONAL,
            locker_bank_id: this.state.selectedLocker.locker_bank,
            locker_size: this.state.selectedLocker.locker_size,
            locker_selection_mode: "any_available",
            locker_unit: this.state.selectedLocker.locker_unit,
            booking_duration_in_hours: Math.ceil(this.state.selectedLocker.overstayDurationInMilliseconds / 3600000),
            locker_price: this.state.overstayAmount
        };
        const response = await prePaymentRegistration(this.state.domain, additionalHeaders, body);

        if (response.status === SUCCESS) {
            if (response.data?.data?.payment_initiated_key?.status === 1) {
                let userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
                userInfo.refId = response.data?.data?.next_ref_id;
                storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_GUEST_USER, userInfo);

                this.props.history.push({
                    pathname: "/payment-details",
                    state: {
                        currentReferenceId: response.data?.data?.ref_id,
                        paymentGatewayAccessKey: response.data?.data?.payment_initiated_key?.data,
                        redirectionPath: "/my-lockers"
                    }
                });
            } else{
                //free locker
                this.props.history.push({
                    pathname: "/payment-completion",
                    state: {
                        transactionDetails: null,
                        assignedLockerInfo: response.data.data, 
                    }
                });
            }
        }
        else if (response.status === ERROR) {
            this.setState({
                isLoading: false
            }, () => {
                ShowToast(this.props.t(response.message), TOAST_ERROR);
            });
        }
        else if (response.status === REDIRECT) {
            this.setState({
                isLoading: false
            }, () => {
                this.props.history.push({
                    pathname: "/",
                    state: {
                        message: this.props.t(response.message),
                        messageType: TOAST_ERROR
                    }
                });
            });
        }
    }

    bookAnotherLockerButtonHandler = () => {
        this.setState({
            isLoading: true,
            loadingMessage: this.props.t('verifying_locker_bank_settings_please_wait'),
            showQRCodeReader: true
        });
    }

    cancelQRCodeScanButtonHandler = () => {
        this.setState({
            isLoading: false,
            loadingMessage: "",
            showQRCodeReader: false
        });
    }

    qrReaderScanHandler = (qrCodeData) => {
        if (qrCodeData) {
            this.setState({
                showQRCodeReader: false
            }, async () => {
                const domainAndLockerBankInfo = getDomainAndLockerBankInfoFromScannedQRCode(qrCodeData);

                if (domainAndLockerBankInfo) {
                    if (domainAndLockerBankInfo.domain === this.state.domain) {
                        const configurationSettings = await getTenantAssignmentLockerBankConfigurationSettings(domainAndLockerBankInfo.domain, domainAndLockerBankInfo.lockerBankId);

                        if (configurationSettings.tenantAssignmentAndLockerBankSettings) {
                            if (configurationSettings.availableLockersCount > 0) {
                                if (domainAndLockerBankInfo.lockerBankId !== this.state.lockerBankId) {
                                    storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, domainAndLockerBankInfo);
                                }
                                storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, configurationSettings.tenantAssignmentAndLockerBankSettings);

                                this.setState({
                                    stopCountdownTimersAndClearIntervalWhenTrue: true
                                }, () => {
                                    ShowToast(this.props.t('success_msg_you_are_now_accessing_locker_bank', {lockerBankName: configurationSettings.tenantAssignmentAndLockerBankSettings.lockerBankName}), TOAST_SUCCESS);
                                    this.props.history.push({
                                        pathname: "/locker-size"
                                    });
                                })
                            }
                            else {
                                ShowToast(this.props.t('error_msg_locker_units_not_available_try_later'), TOAST_ERROR);
                            }
                        }
                        else {
                            ShowToast(this.props.t(configurationSettings.errorMessage), TOAST_ERROR);
                        }
                    }
                    else {
                        ShowToast(this.props.t('error_msg_locker_bank_is_under_different_tenant'), TOAST_ERROR);
                    }
                }
                else {
                    ShowToast(this.props.t('error_msg_scanned_qr_not_valid'), TOAST_ERROR);
                }

                this.setState({
                    isLoading: false,
                    loadingMessage: ""
                });
            });
        }
    }

    qrReaderErrorHandler = (error) => {
        this.setState({
            isLoading: false,
            loadingMessage: "",
            showQRCodeReader: false
        }, () => {
            ShowToast(this.props.t('error_msg_error_while_opening_qr_reader'), TOAST_ERROR);
        });
    }

    componentWillUnmount = () => {}

    render () {
        return (
            <div className="my-lockers-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">{this.props.t('page_title_my_lockers')}</div>
                        <div className="user" onClick={() => this.userModalHandler()}>
                            <MdAccountCircle className="icon-md-account-circle"/>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            {this.state.loadingMessage && (
                                <div className="loading-message">{this.state.loadingMessage}</div>
                            )}
                        </div>
                    )}
                    {(!this.state.isLoading && this.state.showErrorMessageAndRetryButtonIfUnableToGetAssignedLockers) && (
                        <div className="main">
                            <div className="error-message">{this.props.t('error_while_retrieving_lockers')}</div>
                            <div className="try-again-button" onClick={() => this.tryAgainButtonHandler()}>{this.props.t('button_try_again')}</div>
                        </div>
                    )}
                    {(!this.state.isLoading && this.state.isOpeningLockerUnit) && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            <div className="loading-text">{this.props.t('opening_locker_please_wait', {lockerUnit: this.state.selectedLocker.locker_unit})}</div>
                        </div>
                    )}
                    {(!this.state.isLoading && !this.state.showErrorMessageAndRetryButtonIfUnableToGetAssignedLockers && !this.state.isOpeningLockerUnit) && (
                        <div className="main">
                            {this.state.assignedLockersList.length > 0 ? (
                                <div className="booked-lockers-list">
                                    {this.state.assignedLockersList.map((locker, index) => (
                                        <div className={((this.state.selectedLocker?.locker_bank === locker.locker_bank) && (this.state.selectedLocker?.locker_bank === locker.locker_unit)) ? `booked-locker accordion-expanded` : `booked-locker accordion`} key={index} onClick={() => this.setState({selectedLocker: locker})}>
                                            <div className="locker-information-container">
                                                <div className="locker-information">
                                                    <div className="locker-unit-container">
                                                        <div className="icon-container">
                                                            <RiDoorLockBoxFill className="icon-ri-door-lock-box-fill" />
                                                        </div>
                                                        <div className="locker-unit">{locker.locker_unit}</div>
                                                    </div>
                                                    {( !((this.state.selectedLocker?.locker_bank === locker.locker_bank) && (this.state.selectedLocker?.locker_unit === locker.locker_unit)) ) && (
                                                        <div className="locker-bank-name-and-remaining-time-container">
                                                            <div className="locker-bank-name-container">
                                                                <div className="icon-container">
                                                                    <GiLockers className="icon-gi-lockers" />
                                                                </div>
                                                                <div className="locker-bank-name">{locker.locker_bank_name}</div>
                                                            </div>
                                                            <div className="remaining-time-container">
                                                                <div className="icon-container">
                                                                    <IoTimer className="icon-io-timer" />
                                                                </div>
                                                                <div className="remaining-time">{convertMillisecondsToSpecifiedTimeFormat(locker.remainingTimeInMilliseconds, HH_mm_ss)}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="icon-container chevron-icon-container">
                                                    <MdChevronRight className="icon-md-chevron-right" />
                                                </div>
                                            </div>
                                            {((this.state.selectedLocker?.locker_bank === locker.locker_bank) && (this.state.selectedLocker?.locker_unit === locker.locker_unit)) && (
                                                <div className="locker-detailed-information-container">
                                                    <div className="locker-bank-name-container">
                                                        <div className="icon-container">
                                                            <GiLockers className="icon-gi-lockers" />
                                                        </div>
                                                        <div className="locker-bank-name">{locker.locker_bank_name}</div>
                                                    </div>
                                                    <div className="locker-bank-location-container">
                                                        <div className="icon-container">
                                                            <MdLocationOn className="icon-md-location-on" />
                                                        </div>
                                                        <div className="locker-bank-location">{locker.locker_bank_location}</div>
                                                    </div>
                                                    <div className="booking-duration-container">
                                                        <div className="icon-container">
                                                            <IoTime className="icon-io-time" />
                                                        </div>
                                                        <div className="booking-duration">{`${moment(locker.booking_start_time).format("DD MMM, HH:mm")} - ${moment(locker.booking_end_time).format("DD MMM, HH:mm")}`}</div>
                                                    </div>
                                                    {( !(locker.hasGracePeriodStarted) && !(locker.isOverstay) ) && (
                                                        <div className="remaining-time-container">
                                                            <div className="icon-container">
                                                                <IoTimer className="icon-io-timer" />
                                                            </div>
                                                            <div className="remaining-time">{convertMillisecondsToSpecifiedTimeFormat(locker.remainingTimeInMilliseconds, HH_mm_ss)}</div>
                                                        </div>
                                                    )}
                                                    {( locker.hasGracePeriodStarted && !(locker.isOverstay) ) && (
                                                        <div className="remaining-grace-time-container">
                                                            <div className="icon-container">
                                                                <IoTimer className="icon-io-timer" />
                                                            </div>
                                                            <div className="remaining-grace-time">{convertMillisecondsToSpecifiedTimeFormat(locker.remainingTimeInMilliseconds, HH_mm_ss)}</div>
                                                        </div>
                                                    )}
                                                    {( locker.hasGracePeriodStarted && locker.isOverstay ) && (
                                                        <div className="remaining-time-and-overstay-time-container">
                                                            <div className="remaining-time-container">
                                                                <div className="icon-container">
                                                                    <IoTimer className="icon-io-timer" />
                                                                </div>
                                                                <div className="remaining-time">{convertMillisecondsToSpecifiedTimeFormat(locker.remainingTimeInMilliseconds, HH_mm_ss)}</div>
                                                            </div>
                                                            <div className="overstay-time-container">
                                                                <div className="icon-container">
                                                                    <MdMoreTime className="icon-md-more-time" />
                                                                </div>
                                                                <div className="overstay-time">{convertMillisecondsToSpecifiedTimeFormat(locker.overstayDurationInMilliseconds, HH_mm_ss)}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {( locker.hasGracePeriodStarted || locker.isOverstay ) && (
                                                        <div className={locker.isOverstay ? "warning-message-container overstay-warning" : "warning-message-continer"}>
                                                            {locker.isOverstay ? (
                                                                <div className="warning-message grace-period-warning-message">{this.props.t('booking_time_over_msg')}</div>
                                                            ) : (
                                                                <div className="warning-message overstay-warning-message">{this.props.t('booking_time_over_grace_time_msg', {bookingGraceTime: (locker.booking_end_time_grace - locker.booking_end_time) / 60000})}</div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {locker.isOverstay ? (
                                                        <div className="overstay-pay-button" onClick={() => this.overstayPayButtonHandler()}>{this.props.t('pay_text')}</div>
                                                    ) : (
                                                        <SlideButton
                                                            mainText="Slide to open locker"
                                                            overlayText={this.props.t('button_open_locker')}
                                                            caret={CHEVRONS_RIGHT()}
                                                            classList="slide-button"
                                                            overlayClassList="slide-button-overlay"
                                                            caretClassList="slide-button-caret"
                                                            minSlideWidth={0.8}
                                                            minSlideVelocity={1}
                                                            onSlideDone={() => this.openSelectedLocker()}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="booked-lockers-list">
                                    <div className="warning-message">{this.props.t('no_locker_booked_msg')}</div>
                                </div>
                            )}
                            {(this.state.userInfo.assignedLockersCount < this.state.tenantAssignmentAndLockerBankSettings.allowedLockersCount) ? (
                                <div className="book-another-locker-button" onClick={() => this.bookAnotherLockerButtonHandler() }>{this.props.t('button_book_new_locker')}</div>
                            ) : (
                                <div className="book-another-locker-button disabled-button" onClick={() => ShowToast(this.props.t('warning_msg_max_lockers_booked_release_before_booking_new_one'), TOAST_WARN)}>{this.props.t('button_book_new_locker')}</div>
                            )}
                        </div>
                    )}
                </div>
                <ReactModal
                    isOpen={this.state.showUserModal}
                    onRequestClose={() => this.setState({showUserModal: false})}
                    className="react-modal user-modal"
                    overlayClassName="react-modal-overlay user-modal-overlay"
                >
                    <UserModal logoutHandlerFunction={() => this.logoutHandler()} />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showPaymentConfirmationModal}
                    onRequestClose={() => this.setState({showPaymentConfirmationModal: false})}
                    className="react-modal payment-confirmation-modal"
                    overlayClassName="react-modal-overlay payment-confirmation-modal-overlay"
                >
                    <div className="payment-confirmation-modal-content">
                        <div className="payment-info">
                            <div className="info-row">
                                <div className="title">{this.props.t('locker_size')}</div>
                                <div className="value">{this.state.selectedLocker?.locker_size}</div>
                            </div>
                            <div className="info-row">
                                <div className="title">{this.props.t('locker')}</div>
                                <div className="value">{this.state.selectedLocker?.locker_unit}</div>
                            </div>
                            <div className="info-row">
                                <div className="title overstay-duration-title">{this.props.t('overstay_duration')}</div>
                                <div className="value overstay-duration-value">{convertMillisecondsToSpecifiedTimeFormat(this.state.selectedLocker?.overstayDurationInMilliseconds, HH_mm_ss)}</div>
                            </div>
                            <div className="info-row">
                                <div className="title">{this.props.t('total_amount')}</div>
                                <div className="value">{this.state.overstayAmount ? `${this.state.overstayAmount} Rs.` : this.props.t('freeText')}</div>
                            </div>
                        </div>
                        <div className="buttons-container">
                            <div className="cancel-button" onClick={() => this.setState({showPaymentConfirmationModal: false})}>{this.props.t('button_cancel')}</div>
                            <div className="pay-button" onClick={() => this.makePrePaymentRegistration()}>{this.props.t('button_pay')}</div>
                        </div>
                    </div>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showQRCodeReader}
                    onRequestClose={() => {}}
                    className="react-modal qr-code-reader-modal"
                    overlayClassName="react-modal-overlay qr-code-reader-modal-overlay"
                >
                    <div className="qr-code-reader-container">
                        <QrReader
                            className="qr-code-reader"
                            delay={300}
                            onScan={(data) => this.qrReaderScanHandler(data)}
                            onError={(error) => this.qrReaderErrorHandler(error)}
                        />
                        <div className="button-container">
                            <div className="cancel-button" onClick={() => this.cancelQRCodeScanButtonHandler()}>{this.props.t('button_cancel')}</div>
                        </div>
                    </div>
                </ReactModal>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(MyLockersPage);