import {Component} from "react";
import ReactModal from "react-modal";
import QrReader from "react-qr-reader";
import {TOAST_SUCCESS, TOAST_WARN, TOAST_ERROR, OPEN_LOCKER} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, LS_GUEST_USER} from "../assets/constants/BrowserStorageKeys";
import {MdAccountCircle} from "react-icons/md";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {isGuestUserAuthenticated, getTenantAssignmentLockerBankConfigurationSettings} from "../lib/AuthUtils";
import {getDomainAndLockerBankInfoFromScannedQRCode} from "../lib/Utils";
import {storeSecureItemInSpecifiedStorage, getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";
import UserModal from "../components/UserModal";
import Footer from "../components/Footer";

class LockerReleasedPage extends Component {
    constructor (props) {
        super (props)

        const tenantAssignmentAndLockerBankSettings = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS);
        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const domain = domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null;
        const lockerBankId = domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null;
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);
        const releasedLockerInfo = this.props.history.location.state?.releasedLockerInfo;

        this.state = {
            isLoading: true,
            loadingMessage: null,
            showUserModal: false,
            showQRCodeReader: false,
            domain: domain,
            lockerBankId: lockerBankId,
            tenantAssignmentAndLockerBankSettings: tenantAssignmentAndLockerBankSettings,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            releasedLockerInfo: releasedLockerInfo,
        }
    }

    componentDidMount = () => {
        if (this.state.isUserAuthenticated) {
            this.setState({
                isLoading: false
            });
        }
    }

    userModalHandler = () => {
        this.setState({
            showUserModal: true
        });
    }

    logoutHandler = () => {
        localStorage.removeItem(LS_GUEST_USER);
        sessionStorage.clear();

        this.props.history.push({
            pathname: "/",
            state: {
                message: this.props.t('success_msg_successfully_logged_out'),
                messageType: TOAST_SUCCESS
            }
        });
    }

    myLockersButtonHandler = () => {
        localStorage.setItem("userAction", OPEN_LOCKER);

        this.props.history.push({
            pathname: "/my-lockers"
        });
    }

    bookAnotherLockerButtonHandler = () => {
        this.setState({
            isLoading: true,
            loadingMessage: this.props.t('verifying_locker_bank_settings_please_wait'),
            showQRCodeReader: true
        });
    }

    cancelQRCodeScanButtonHandler = () => {
        this.setState({
            isLoading: false,
            loadingMessage: "",
            showQRCodeReader: false
        });
    }

    qrReaderScanHandler = (qrCodeData) => {
        if (qrCodeData) {
            this.setState({
                showQRCodeReader: false
            }, async () => {
                const domainAndLockerBankInfo = getDomainAndLockerBankInfoFromScannedQRCode(qrCodeData);

                if (domainAndLockerBankInfo) {
                    if (domainAndLockerBankInfo.domain === this.state.domain) {
                        const configurationSettings = await getTenantAssignmentLockerBankConfigurationSettings(domainAndLockerBankInfo.domain, domainAndLockerBankInfo.lockerBankId);

                        if (configurationSettings.tenantAssignmentAndLockerBankSettings) {
                            if (configurationSettings.availableLockersCount > 0) {
                                if (domainAndLockerBankInfo.lockerBankId !== this.state.lockerBankId) {
                                    storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, domainAndLockerBankInfo);
                                }
                                storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, configurationSettings.tenantAssignmentAndLockerBankSettings);

                                ShowToast(this.props.t('success_msg_you_are_now_accessing_locker_bank', {lockerBankName: configurationSettings.tenantAssignmentAndLockerBankSettings.lockerBankName}), TOAST_SUCCESS);
                                this.props.history.push({
                                    pathname: "/locker-size"
                                });
                            }
                            else {
                                ShowToast(this.props.t('error_msg_locker_units_not_available_try_later'), TOAST_ERROR);
                            }
                        }
                        else {
                            ShowToast(this.props.t(configurationSettings.errorMessage), TOAST_ERROR);
                        }
                    }
                    else {
                        ShowToast(this.props.t('error_msg_locker_bank_is_under_different_tenant'), TOAST_ERROR);
                    }
                }
                else {
                    ShowToast(this.props.t('error_msg_scanned_qr_not_valid'), TOAST_ERROR);
                }

                this.setState({
                    isLoading: false,
                    loadingMessage: ""
                });
            });
        }
    }

    qrReaderErrorHandler = (error) => {
        this.setState({
            isLoading: false,
            loadingMessage: "",
            showQRCodeReader: false
        }, () => {
            ShowToast(this.props.t('error_msg_error_while_opening_qr_reader'), TOAST_ERROR);
        });
    }
    
    componentWillUnmount = () => {}

    render () {
        return (
            <div className="locker-released-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">{this.props.t('page_title_locker_released')}</div>
                        <div className="user" onClick={() => this.userModalHandler()}>
                            <MdAccountCircle className="icon-md-account-circle"/>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            {this.state.loadingMessage && (
                                <div className="loading-message">{this.state.loadingMessage}</div>
                            )}
                        </div>
                    ) : (
                        <div className="main">
                            <div className="locker-released-info">{this.props.t('locker_unit_is_released', {lockerUnit: this.state.releasedLockerInfo?.lockerUnit})}</div>
                            <div className="locker-released-instructions">{this.props.t('take_belongings_and_close_locker')}</div>
                            <div className="buttons-container">
                                {(this.state.userInfo.assignedLockersCount > 0) && (
                                    <div className="my-lockers-button" onClick={() => this.myLockersButtonHandler()}>{this.props.t('button_my_lockers')}</div>
                                )}
                                {this.state.userInfo.assignedLockersCount < this.state.tenantAssignmentAndLockerBankSettings.allowedLockersCount ? (
                                    <div className="book-new-locker-button" onClick={() => this.bookAnotherLockerButtonHandler()}>{this.props.t('button_book_new_locker')}</div>
                                ) : (
                                    <div className="book-new-locker-button disabled-button" onClick={() => ShowToast(this.props.t('warning_msg_max_lockers_booked_release_before_booking_new_one'), TOAST_WARN)}>{this.props.t('button_book_new_locker')}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <ReactModal
                    isOpen={this.state.showUserModal}
                    onRequestClose={() => this.setState({showUserModal: false})}
                    className="react-modal user-modal"
                    overlayClassName="react-modal-overlay user-modal-overlay"
                >
                    <UserModal logoutHandlerFunction={() => this.logoutHandler()}/>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showQRCodeReader}
                    onRequestClose={() => {}}
                    className="react-modal qr-code-reader-modal"
                    overlayClassName="react-modal-overlay qr-code-reader-modal-overlay"
                >
                    <div className="qr-code-reader-container">
                        <QrReader
                            className="qr-code-reader"
                            delay={300}
                            onScan={(data) => this.qrReaderScanHandler(data)}
                            onError={(error) => this.qrReaderErrorHandler(error)}
                        />
                        <div className="button-container">
                            <div className="cancel-button" onClick={() => this.cancelQRCodeScanButtonHandler()}>{this.props.t('button_cancel')}</div>
                        </div>
                    </div>
                </ReactModal>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(LockerReleasedPage);