import moment from "moment";
import {PWA_DOMAIN_DEV, PWA_DOMAIN_PROD, LOCKER_BANK, HH_mm_ss} from "../assets/constants/Constants";
import {DEFAULT_LANGUAGE_ENGLISH} from "../assets/constants/LanguageOptions";

export const getDomainAndLockerBankInfoFromScannedQRCode = (qrCodeData) => {
    const luggagePWALinkPattern = new RegExp(`(${PWA_DOMAIN_DEV}|${PWA_DOMAIN_PROD})/${LOCKER_BANK}/.*`);

    if (luggagePWALinkPattern.test(qrCodeData)) {
        const domainAndLockerBankInfoInBase64 = qrCodeData.split(`/${LOCKER_BANK}/`)[1];
        const domainAndLockerBankInformation = window.atob(domainAndLockerBankInfoInBase64);
        const isDomainValid = /^{.*(?="domain"\s*:\s*"[^"]{1,}").*}$/.test(domainAndLockerBankInformation);
        const isLockerBankIdValid = /^{.*(?="lockerBankId"\s*:\s*"\d+").*}$/.test(domainAndLockerBankInformation);

        if (isDomainValid && isLockerBankIdValid) {
            return JSON.parse(domainAndLockerBankInformation);
        }
    }

    return null;
}

export const retrieveDetailsOfPreviouslySelectedLanguageFromLocalStorage = () => {
    let previouslySelectedLanguage = DEFAULT_LANGUAGE_ENGLISH;

    if (localStorage.getItem("selectedLanguage")) {
        previouslySelectedLanguage = JSON.parse(localStorage.getItem("selectedLanguage"));
    }

    return previouslySelectedLanguage;
}

export const retrieveISOCodeOfPreviouslySelectedLanguageFromLocalStorage = () => {
    let previouslySelectedLanguage = DEFAULT_LANGUAGE_ENGLISH;

    if (localStorage.getItem("selectedLanguage")) {
        previouslySelectedLanguage = JSON.parse(localStorage.getItem("selectedLanguage"));
    }

    return previouslySelectedLanguage.value;
}

export const convertMillisecondsToSpecifiedTimeFormat = (milliseconds, timeFormat) => {
    const duration = moment.duration(milliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = moment.utc(milliseconds).format("mm");

    if (timeFormat === HH_mm_ss) {
        const seconds = moment.utc(milliseconds).format("ss");
        return `${hours}:${minutes}:${seconds}`;
    }

    return `${hours}:${minutes}`;
}