export const APP_GREEN_COLOR = "#669F00";
export const APP_YELLOW_GREEN_COLOR = "#92C23E";
export const APP_TEA_GREEN_COLOR = "#DFF8B1";
export const APP_ENGLISH_VERMILLION_COLOR = "#C75252";
export const APP_BITTERSWEET_COLOR = "#FF6464";
export const APP_DARK_PURPLE_COLOR = "#1A051D";
export const APP_ENGLISH_VIOLET_COLOR = "#3F3356";
export const APP_GREY_COLOR = "#808080";
export const APP_LIGHT_GREY_COLOR = "#D3D3D3";
export const APP_LAVENDAR_GREY_COLOR = "#D0C9D6";
export const APP_WHITE_COLOR = "#FFFFFF";
export const APP_GHOST_WHITE_COLOR = "#F7F5F9";