import {APP_GREEN_COLOR, APP_DARK_PURPLE_COLOR} from "./Colors";

export const ROTATING_LOADING_ICON = (iconFillColor = APP_GREEN_COLOR) => {
    return (
        <svg id="rotating-loading-icon" x="0px" y="0px" viewBox="25 25 50 50">
            <path fill={iconFillColor} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    );
};

export const CHEVRONS_RIGHT = (iconFillColor = APP_DARK_PURPLE_COLOR) => {
    return (
        <svg id="chevrons-right" viewBox="0 0 22.25 25.5">
            <g transform="translate(-54.75 -176.75)">
                <g transform="translate(77 202.25) rotate(180)">
                    <path fill={iconFillColor} d="M2.573,12.75,13.188,23.634a1.113,1.113,0,0,1,0,1.546,1.047,1.047,0,0,1-1.507,0L.312,13.523a1.113,1.113,0,0,1,0-1.546L11.681.32a1.047,1.047,0,0,1,1.507,0,1.113,1.113,0,0,1,0,1.546Z" />
                </g>
                <g transform="translate(68.25 202.25) rotate(180)">
                    <path fill={iconFillColor} d="M2.573,12.75,13.188,23.634a1.113,1.113,0,0,1,0,1.546,1.047,1.047,0,0,1-1.507,0L.312,13.523a1.113,1.113,0,0,1,0-1.546L11.681.32a1.047,1.047,0,0,1,1.507,0,1.113,1.113,0,0,1,0,1.546Z" />
                </g>
            </g>
        </svg>
    );
}