import {Component} from "react";
import {withTranslation} from "react-i18next";
import QrReader from "react-qr-reader";
import {SUCCESS, GUEST_AUTH_TOKEN, GUEST_AUTH_TOKEN_VALUE, TOAST_WARN, TOAST_ERROR, TOAST_INFO} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, LS_GUEST_USER} from "../assets/constants/BrowserStorageKeys";
import {GiLockers} from "react-icons/gi";
import {MdLocationOn} from "react-icons/md";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {isGuestUserAuthenticated} from "../lib/AuthUtils";
import {getDomainAndLockerBankInfoFromScannedQRCode} from "../lib/Utils";
import {getTenantAndAssignmentSettings} from "../lib/BackendUtils";
import {storeSecureItemInSpecifiedStorage, getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import ShowToast from "../components/ToastComponent";
import Footer from "../components/Footer";
import { renderToString } from 'react-dom/server';

class HomePage extends Component {
    constructor (props) {
        super (props)

        sessionStorage.clear();

        const tenantAssignmentAndLockerBankSettings = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS);
        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);

        this.state = {
            isLoading: true,
            messageFromPreviousPage: (this.props.history.location.state?.message) ? this.props.history.location.state.message : null,
            messageType: (this.props.history.location.state?.messageType) ? this.props.history.location.state.messageType : TOAST_INFO,
            showQRCodeReader: false,
            selectedLanguage: {value: 'en', label: 'English'},
            lockerBankId: domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null,
            domain: domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            lockerBankName: tenantAssignmentAndLockerBankSettings?.lockerBankName ? tenantAssignmentAndLockerBankSettings?.lockerBankName : "",
            lockerBankLocation: tenantAssignmentAndLockerBankSettings?.lockerBankLocation ? tenantAssignmentAndLockerBankSettings?.lockerBankLocation : "",
            areTermsAndConditionsAccepted: false,
            areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase: true
        }
    }

    componentDidMount = async () => {
        if (this.state.messageFromPreviousPage) {
            ShowToast(this.state.messageFromPreviousPage, this.state.messageType);
        }

        if (this.state.domain !== null && this.state.lockerBankId !== null) {
            await this.getTenantAndAssignmentSettingsAndCheckIfLockerBankIsConfiguredForLuggageUseCase();

            if (this.state.areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase && this.state.isUserAuthenticated) {
                this.props.history.push({
                    pathname: (this.state.userInfo?.assignedLockersCount > 0) ? "/my-lockers" : "/locker-size"
                });
            }
        }

        this.setState({
            isLoading: false
        });
    }

    qrReaderScanHandler = (qrCodeData) => {
        if (qrCodeData) {
            // console.log('qr code data: ', qrCodeData);

            if (getDomainAndLockerBankInfoFromScannedQRCode(qrCodeData)) {
                // console.log('the scanned code has valid link');
            }
        }
    }

    qrReaderErrorHandler = (error) => {
        // console.log('qr code error: ', error);
    }

    getTenantAndAssignmentSettingsAndCheckIfLockerBankIsConfiguredForLuggageUseCase = async () => {
        const additionalHeaders = {[GUEST_AUTH_TOKEN]: GUEST_AUTH_TOKEN_VALUE};
        const body = {locker_bank: this.state.lockerBankId};
        const response = await getTenantAndAssignmentSettings(this.state.domain, additionalHeaders, body);

        if (response.status === SUCCESS) {
            let areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = true;
            let isTenantActive = false;
            let isTenantConfiguredForLuggageUseCase = false;
            let isAssignmentActive = false;
            let isAssignmentConfiguredForLuggageUseCase = false;
            let isOTPLoginEnabled = false;
            let isEmailAddressEnabledForOTPLogin = false;
            let isMobileNumberEnabledForOTPLogin = false;
            let isOTPlessLoginEnabled = false;
            let isAutomaticLockerUnitAssignmentEnabled = false;
            let isManualLockerUnitSelectionEnabled = false;
            let maximumLockerUnitsAllowedPerUser = 0;
            let assignmentPricing = null;
            let lockerBankName = "";
            let lockerBankLocation = "";

            if (response.data.data.tenant_settings && response.data.data.assignments) {
                isTenantActive = response.data.data.tenant_settings.active === true ? true : false;
                isTenantConfiguredForLuggageUseCase = response.data.data.tenant_settings.use_case_settings.is_luggage === true ? true : false;
                isEmailAddressEnabledForOTPLogin = response.data.data.tenant_settings.use_case_settings.luser_email_mand === true ? true : false;
                isMobileNumberEnabledForOTPLogin = response.data.data.tenant_settings.use_case_settings.luser_mobile_no_mand === true ? true : false;
                isAssignmentActive = response.data.data.assignments[0].is_active === true ? true : false;
                maximumLockerUnitsAllowedPerUser = response.data.data.assignments[0].lockers_allowed;

                const assignmentSettings = response.data.data.assignments[0].settings_util;
                assignmentPricing = response.data.data.assignments[0].assignment_pricing_data[this.state.lockerBankId];
                isAssignmentConfiguredForLuggageUseCase = assignmentSettings.luggage === true ? true : false;
                isOTPLoginEnabled = assignmentSettings.verify_with_otp === true ? true : false;
                isOTPlessLoginEnabled = assignmentSettings.otpless === true ? true : false;
                isAutomaticLockerUnitAssignmentEnabled = assignmentSettings.any_available === true ? true : false;
                isManualLockerUnitSelectionEnabled = assignmentSettings.select_manual === true ? true : false;
                lockerBankName = response.data.data.locker_bank_information.name ? response.data.data.locker_bank_information.name : "";
                lockerBankLocation = response.data.data.locker_bank_information.location ? response.data.data.locker_bank_information.location : "";

                if (!isTenantActive) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (!isTenantConfiguredForLuggageUseCase) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (!isAssignmentActive) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (!isAssignmentConfiguredForLuggageUseCase) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (maximumLockerUnitsAllowedPerUser < 1) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (!isOTPLoginEnabled && !isOTPlessLoginEnabled) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (isOTPLoginEnabled && (!isEmailAddressEnabledForOTPLogin && !isMobileNumberEnabledForOTPLogin)) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
                else if (!isAutomaticLockerUnitAssignmentEnabled && !isManualLockerUnitSelectionEnabled) {
                    areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase = false;
                }
            }

            this.setState({
                lockerBankName: lockerBankName,
                lockerBankLocation: lockerBankLocation,
                areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase: areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase
            }, () => {
                if (areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase) {
                    const tenantAssignmentAndLockerBankSettings = {
                        isOTPLoginEnabled: isOTPLoginEnabled,
                        isEmailAddressEnabledForOTPLogin: isEmailAddressEnabledForOTPLogin,
                        isMobileNumberEnabledForOTPLogin: isMobileNumberEnabledForOTPLogin,
                        isOTPlessLoginEnabled: isOTPlessLoginEnabled,
                        allowedLockersCount: maximumLockerUnitsAllowedPerUser,
                        assignmentPricing: assignmentPricing,
                        lockerBankName: lockerBankName,
                        lockerBankLocation: lockerBankLocation
                    }

                    storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, tenantAssignmentAndLockerBankSettings);
                }
            });
        }
        else {
            this.setState({
                areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase: false
            }, () => {
                ShowToast(this.props.t(response.message), TOAST_ERROR);
            });
        }
    }

    loginHandler = (userAction) => {
        this.props.history.push({
            pathname: "/login"
        });
    }

    tryAgainButtonHandler = () => {
        this.setState({
            isLoading: true
        }, async () => {
            await this.getTenantAndAssignmentSettingsAndCheckIfLockerBankIsConfiguredForLuggageUseCase();
        });

        this.setState({
            isLoading: false
        });
    }

    componentWillUnmount = () => {}

    render () {
        const acceptText = this.props.t('accept_text');
        const termAndConditionText = this.props.t('term_and_condition_text');
        const termsLink = renderToString(<a href="https://www.fonzel.com/terms-and-conditions/" target="_blank" rel="noreferrer" className="terms-and-conditions-page-link">{termAndConditionText}</a>);
        const fullText = acceptText.replace('{{link}}', termsLink);
    
        return (
            <div className="home-page">
                <div className="header-container">
                    <div className="header">
                        <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={this.props.t('durolt_logo')}/>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                        </div>
                    )}
                    {((!this.state.isLoading) && (this.state.domain === null || this.state.lockerBankId === null)) && (
                        <div className="main">
                            <div className="locker-bank-under-maintenance">
                                <div className="error-message">{this.props.t('locker_bank_not_recognised')}</div>
                            </div>
                        </div>
                    )}
                    {((!this.state.isLoading) && (this.state.domain !== null && this.state.lockerBankId !== null) && (this.state.areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase)) && (
                        <div className="main">
                            {this.state.showQRCodeReader ? (
                                <div className="qr-code-reader-container">
                                    <QrReader
                                        className="qr-code-reader"
                                        delay={300}
                                        onScan={(qrCodeData) => this.qrReaderScanHandler(qrCodeData)}
                                        onError={(error) => this.qrReaderErrorHandler(error)}
                                    />
                                    <div className="buttons-container">
                                        <div className="cancel-scan-button" onClick={() => this.setState({showQRCodeReader: false})}>{this.props.t('button_cancel')}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="locker-bank-information-and-login-button-container">
                                    <div className="locker-bank-information-container">
                                        <div className="locker-bank-information">
                                            <div className="locker-bank-name-container">
                                                <div className="icon-container">
                                                    <GiLockers className="icon-gi-lockers" />
                                                </div>
                                                <div className="locker-bank-name">{this.state.lockerBankName}</div>
                                            </div>
                                            <div className="locker-bank-location-container">
                                                <div className="icon-container">
                                                    <MdLocationOn className="icon-md-location-on" />
                                                </div>
                                                <div className="locker-bank-location">{this.state.lockerBankLocation}</div>
                                            </div>
                                            {/* <div className="information-message">If you are trying to use a different locker bank please scan the QR code on that locker bank.</div> */}
                                            {/* <div className="scan-qr-code-button" onClick={() => this.setState({showQRCodeReader: true})}>Scan QR Code</div> */}
                                        </div>
                                    </div>
                                    <form className="terms-and-conditions-form">
                                        <input type="checkbox" id="termsAndConditions" name="termsAndConditions" checked={this.state.areTermsAndConditionsAccpted} onChange={() => this.setState({areTermsAndConditionsAccepted: !this.state.areTermsAndConditionsAccepted})} />
                                        <label htmlFor="termsAndConditions" dangerouslySetInnerHTML={{ __html: fullText }} />
                                    </form>
                                    <div className={this.state.areTermsAndConditionsAccepted ? "login-button" : "login-button disabled-button"} onClick={() => {this.state.areTermsAndConditionsAccepted ? this.loginHandler() : ShowToast(this.props.t('warning_msg_please_accept_tnc'), TOAST_WARN)}}>{this.props.t('button_login')}</div>
                                </div>
                            )}
                        </div>
                    )}
                    {((!this.state.isLoading) && (this.state.domain !== null && this.state.lockerBankId !== null) && (!this.state.areBothTenantAndAssignmentActiveAndProperlyConfiguredForLuggageUseCase)) && (
                        <div className="main">
                            {this.state.showQRCodeReader ? (
                                <div className="qr-code-reader-container">
                                    <QrReader
                                        className="qr-code-reader"
                                        delay={300}
                                        onScan={(data) => this.qrReaderScanHandler(data)}
                                        onError={(error) => this.qrReaderErrorHandler(error)}
                                    />
                                </div>
                            ) : (
                                <div className="locker-bank-under-maintenance">
                                    {/* <div className="locker-bank-information-container">
                                        <div className="locker-bank-name-container">
                                            <div className="icon-container">
                                                <GiLockers className="icon-gi-lockers" />
                                            </div>
                                            <div className="locker-bank-name">Locker bank name</div>
                                        </div>
                                        <div className="locker-bank-location-container">
                                            <div className="icon-container">
                                                <MdLocationOn className="icon-md-location-on" />
                                            </div>
                                            <div className="locker-bank-location">Locker bank location</div>
                                        </div>
                                        <div className="information-message">If you are trying to use a different locker bank please scan the QR code on that locker bank.</div>
                                        <div className="scan-qr-code-button" onClick={() => this.setState({showQRCodeReader: true})}>Scan QR Code</div>
                                    </div> */}
                                    <div className="error-message">{this.props.t('locker_bank_under_maintenance')}</div>
                                    <div className="try-again-button" onClick={() => this.tryAgainButtonHandler() }>{this.props.t('button_try_again')}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(HomePage);