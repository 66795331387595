import {Component} from "react";
import moment from "moment";
import ReactModal from "react-modal";
import {LUGGAGE, SUCCESS, ERROR, TOAST_WARN, BOOK_NEW_LOCKER, BOOKING_TYPE_NEW, REDIRECT, TOAST_ERROR, TOAST_SUCCESS} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_GUEST_USER} from "../assets/constants/BrowserStorageKeys"
import {MdAccountCircle, MdChevronLeft} from "react-icons/md";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {isGuestUserAuthenticated} from "../lib/AuthUtils";
import {getLockerSizeAvailability, prePaymentRegistration} from "../lib/BackendUtils";
import {storeSecureItemInSpecifiedStorage, getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";
import UserModal from "../components/UserModal";
import Footer from "../components/Footer";

class LockerSizePage extends Component {
    constructor (props) {
        super (props)

        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const domain = domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null;
        const lockerBankId = domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null;
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);
        this.handleBackButton = this.handleBackButton.bind(this);

        this.state = {
            isLoading: true,
            loadingMessage: null,
            checkingIfSelectedLockerSizeIsStillAvailable: false,
            showLockerDimensionsIfAvailable: true,
            showBookingDurationTimestampsIfTrueAndWarningIfFalse: true,
            updateBookingStartAndEndTimesAfterEveryMinute: false,
            showUserModal: false,
            showBookingConfirmationModal: false,
            domain: domain,
            lockerBankId: lockerBankId,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            currentReferenceId: userInfo?.refId,
            availableLockerSizes: [],
            minimumBookingDurationInHours: 1,
            selectedLockerSize: {size: null},
            selectedBookingDurationInHours: 1,
            bookingDurationStartTimestamp: null,
            bookingDurationEndTimestamp: null,
            totalAmount: 0,
            remainingTimeToMakeSuccessfulPayment: null,
            paymentGatewayAccessKey: null
        }
    }

    componentDidMount = async () => {
        window.addEventListener('popstate', this.handleBackButton);
        let availableLockerSizes = [];

        if ((this.state.domain !== null) && (this.state.lockerBankId !== null) && (this.state.isUserAuthenticated)) {
            localStorage.setItem("userAction", BOOK_NEW_LOCKER);
            availableLockerSizes = await this.getAvailableLockerSizes();
        }
        
        this.setState({
            isLoading: false,
            availableLockerSizes: availableLockerSizes
        });
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handleBackButton);
    }
    
    handleBackButton(event) {
        const currentPage = window.location.pathname;
        if(currentPage === '/payment-details'){
            this.props.history.push({
                pathname: "/my-lockers"
            });
        }
    }

    userModalHandler = () => {
        this.setState({
            showUserModal: true
        });
    }

    logoutHandler = () => {
        localStorage.removeItem(LS_GUEST_USER);
        sessionStorage.clear();

        this.props.history.push({
            pathname: "/",
            state: {
                message: this.props.t('success_msg_successfully_logged_out'),
                messageType: TOAST_SUCCESS
            }
        });
    }

    getAvailableLockerSizes = async () => {
        let availableLockerSizes = [];
        let additionalHeaders = {'Authorization': `token ${this.state.userInfo.authToken}`};
        let body = {ref_id: this.state.currentReferenceId};
        let response = await getLockerSizeAvailability(this.state.domain, additionalHeaders, body, this.state.lockerBankId);

        if (response.status === SUCCESS) {
            if (response.data.data?.locker_sizes) {
                response.data.data.locker_sizes.forEach((item) => {
                    if (item.size !== null && item.size !== '' && item.name !== null && item.name !== '') {
                        availableLockerSizes.push(item);

                        if (this.state.showLockerDimensionsIfAvailable && (item.dimension.includes(`None`))) {
                            this.setState({
                                showLockerDimensionsIfAvailable: false
                            });
                        }
                    }
                });

                this.setState({
                    remainingTimeToMakeSuccessfulPayment: response.data.data?.blocking_period_in_seconds
                });
            }
        }
        else if (response.status === ERROR) {
            // ShowToast(this.props.t(response.message), TOAST_ERROR);
            localStorage.removeItem(LS_GUEST_USER);
            sessionStorage.clear();
            this.props.history.push({
                pathname: "/"
            });
            window.location.reload();
        }

        return availableLockerSizes;
    }

    lockerSizeSelectionHandler = (selectedLockerSize) => {
        const currentDateTimeInMilliSeconds = moment().valueOf();

        this.setState({
            selectedLockerSize: selectedLockerSize,
            selectedBookingDurationInHours: this.state.minimumBookingDurationInHours,
            showBookingDurationTimestampsIfTrueAndWarningIfFalse: true,
            bookingDurationStartTimestamp: currentDateTimeInMilliSeconds,
            bookingDurationEndTimestamp: currentDateTimeInMilliSeconds + (this.state.minimumBookingDurationInHours * 3600000),
            totalAmount: parseFloat(selectedLockerSize.price) * this.state.minimumBookingDurationInHours,
            updateBookingStartAndEndTimesAfterEveryMinute: true
        }, () => this.bookingStartAndEndTimesUpdaterAfterEveryMinute() );
    }

    bookingStartAndEndTimesUpdaterAfterEveryMinute = () => {
        const bookingStartAndEndTimesUpdaterInterval = setInterval(() => {
            if (this.state.updateBookingStartAndEndTimesAfterEveryMinute) {
                if (moment().seconds() === 0) {
                    this.setState({
                        bookingDurationStartTimestamp: this.state.bookingDurationStartTimestamp + 60000,
                        bookingDurationEndTimestamp: this.state.bookingDurationEndTimestamp + 60000
                    });
                }
            }
            else {
                clearInterval(bookingStartAndEndTimesUpdaterInterval);
            }
        }, 1000);
    }

    bookingDurationInputHandler = (selectedLockerSize, selectedBookingDurationInHours) => {
        if (selectedBookingDurationInHours.length === 0 || /^[1-9]\d*$/.test(selectedBookingDurationInHours)) {
            const currentDateTimeInMilliSeconds = moment().valueOf();

            this.setState({
                selectedBookingDurationInHours: selectedBookingDurationInHours,
                showBookingDurationTimestampsIfTrueAndWarningIfFalse: selectedBookingDurationInHours.length > 0 ? true : false,
                bookingDurationStartTimestamp: currentDateTimeInMilliSeconds,
                bookingDurationEndTimestamp: (selectedBookingDurationInHours.length > 0) ? (currentDateTimeInMilliSeconds + (parseFloat(selectedBookingDurationInHours) * 3600000)) : currentDateTimeInMilliSeconds,
                totalAmount: (selectedBookingDurationInHours.length > 0) ? (parseFloat(selectedLockerSize.price) * parseFloat(selectedBookingDurationInHours)) : 0
            });
        }
        else {
            ShowToast(this.props.t('warning_msg_min_booking_duration', {minimumBookingDurationInHours: this.state.minimumBookingDurationInHours}), TOAST_WARN);
        }
    }

    nextButtonHandler = () => {
        if (this.state.selectedLockerSize.size !== null && (this.state.selectedBookingDurationInHours >= this.state.minimumBookingDurationInHours)) {
            this.setState({
                showBookingConfirmationModal: true
            });
        }
        else if (this.state.selectedLockerSize.size === null) {
            ShowToast(this.props.t('warning_msg_select_locker_size'), TOAST_WARN);
        }
        else {
            ShowToast(this.props.t('warning_msg_min_booking_duration', {minimumBookingDurationInHours: this.state.minimumBookingDurationInHours}), TOAST_WARN);
        }
    }

    makePrePaymentRegistration = async () => {
        this.setState({
            isLoading: true,
            loadingMessage: this.props.t('initiating_transaction_please_wait'),
            showBookingConfirmationModal: false
        });

        const additionalHeaders = {'Authorization': `token ${this.state.userInfo.authToken}`};
        const body = {
            ref_id: this.state.currentReferenceId,
            use_case: LUGGAGE,
            booking_type: BOOKING_TYPE_NEW,
            locker_bank_id: this.state.lockerBankId,
            locker_size: this.state.selectedLockerSize.size,
            locker_price: this.state.selectedLockerSize.price,
            locker_selection_mode: "any_available",
            locker_unit: null,
            booking_duration_in_hours: this.state.selectedBookingDurationInHours,
            // booking_start_time: this.state.bookingDurationStartTimestamp,
            // booking_end_time: this.state.bookingDurationEndTimestamp,
            // locker_total_price: this.state.totalAmount,
            // payment_status: "pending"
        };
        const response = await prePaymentRegistration(this.state.domain, additionalHeaders, body);
        console.log("prePaymentRegistration response ::",response);
        if (response.status === SUCCESS) {
            let userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
            userInfo.refId = response.data?.data?.next_ref_id;
            storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_GUEST_USER, userInfo);

            if (response.data?.data?.payment_initiated_key?.status === 1) {
                this.props.history.push({
                    pathname: "/payment-details",
                    state: {
                        currentReferenceId: this.state.currentReferenceId,
                        paymentGatewayAccessKey: response.data?.data?.payment_initiated_key?.data,
                        redirectionPath: "/locker-size",
                        remainingTimeToMakeSuccessfulPayment: this.state.remainingTimeToMakeSuccessfulPayment
                    }
                });
            } else {
                //free locker
                userInfo.assignedLockersCount = userInfo.assignedLockersCount + 1;
                storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_GUEST_USER, userInfo);
                this.props.history.push({
                    pathname: "/payment-completion",
                    state: {
                        transactionDetails: null,
                        assignedLockerInfo: response.data.data, 
                    }
                });
            }
        }
        else if (response.status === ERROR) {
            this.setState({
                isLoading: true,
                showBookingConfirmationModal: false
            }, async () => {
                let availableLockerSizes = [];
                availableLockerSizes = await this.getAvailableLockerSizes();

                this.setState({
                    isLoading: false,
                    availableLockerSizes: availableLockerSizes,
                    selectedLockerSize: {size: null},
                    selectedBookingDurationInHours: 1
                }, () => {
                    ShowToast(this.props.t(response.message), TOAST_ERROR);
                });
            });
        }
        else if (response.status === REDIRECT) {
            this.setState({
                showBookingConfirmationModal: false
            }, () => {
                localStorage.removeItem(LS_GUEST_USER);
                sessionStorage.clear();
                this.props.history.push({
                    pathname: "/"
                });
                window.location.reload();
            });
        }
    }

    render () {
        return (
            <div className="locker-size-page">
                <div className="header-container">
                    <div className="header">
                        <div className="back-button icon-container" onClick={() => this.props.history.push({pathname: "/my-lockers"})}>
                            <MdChevronLeft className="icon-md-chevron-left" />
                        </div>
                        <div className="page-heading">{this.props.t('page_title_locker_size')}</div>
                        <div className="user" onClick={() => this.userModalHandler()}>
                            <MdAccountCircle className="icon-md-account-circle"/>
                        </div>
                    </div>
                </div>
                    <div className="main-container">
                        {this.state.isLoading && (
                            <div className="main">
                                <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                                {this.state.loadingMessage && (
                                    <div className="loading-message">{this.state.loadingMessage}</div>
                                )}
                            </div>
                        )}
                        {(!this.state.isLoading && this.state.availableLockerSizes.length > 0) && (
                            <div className="main">
                                <div className="locker-size-form-heading">{this.props.t('select_locker_size')}</div>
                                <form className="locker-size-form">
                                    {this.state.availableLockerSizes.map( (item, index) => (
                                        <div className="form-item" key={index+1}>
                                            <label className={`form-label ${this.state.selectedLockerSize.name === item.name ? 'selected' : (this.state.selectedLockerSize.name === item.name ? '':'blurred')}`} htmlFor={item.name.toLowerCase()}>
                                                <input className="form-input" type="radio" id={item.name.toLowerCase()} name="availableLockerSize" value={item.size} onChange={() => this.lockerSizeSelectionHandler(item)} />
                                                {this.state.showLockerDimensionsIfAvailable ? `${item.name} (${item.dimension})` : item.name}
                                            </label>
                                            {(this.state.selectedLockerSize.size === item.size) && (
                                                <div className="booking-period-form">
                                                    <div className="locker-price">
                                                        <div className="title">{this.props.t('price')}</div>
                                                        <div className="value">{item.price > 0 ? `${item.price} Rs` : this.props.t('freeText')}</div>
                                                    </div>
                                                    <div className="booking-period">
                                                        <div className="title">{this.props.t('duration')}</div>
                                                        <input className="form-input value" type="number" id="bookingDuration" name="bookingDuration" value={this.state.selectedBookingDurationInHours} placeholder="Duration" onChange={(event) => this.bookingDurationInputHandler(item, event.target.value) } />
                                                    </div>
                                                    <div className="booking-start-and-end-times">
                                                        {this.state.showBookingDurationTimestampsIfTrueAndWarningIfFalse ? (
                                                            <div className="value">{`(${moment(this.state.bookingDurationStartTimestamp).format(`DD MMM, HH:mm`)} - ${moment(this.state.bookingDurationEndTimestamp).format(`DD MMM, HH:mm`)})`}</div>
                                                        ) : (
                                                            <div className="booking-duration-warning">{this.props.t('warning_msg_min_booking_duration', {minimumBookingDurationInHours: this.state.minimumBookingDurationInHours})}</div>
                                                        )}
                                                    </div>
                                                    <div className="total-amount">
                                                        <div className="title">{this.props.t('total_amount')}</div>
                                                        <div className="value">{this.state.totalAmount > 0 ? `${this.state.totalAmount} Rs` : this.props.t('freeText')}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </form>
                                <div className={(this.state.selectedLockerSize.size !== null && (this.state.selectedBookingDurationInHours >= this.state.minimumBookingDurationInHours)) ? `next-button` : `next-button disabled-button`} onClick={() => this.nextButtonHandler()}>{this.props.t('button_next')}</div>
                            </div>
                        )}
                        {(!this.state.isLoading && this.state.availableLockerSizes.length === 0) && (
                            <div className="main">
                                <div className="">{this.props.t('locker_not_available')}</div>
                            </div>
                        )}
                    </div>
                    <ReactModal
                        isOpen={this.state.showUserModal}
                        onRequestClose={() => this.setState({showUserModal: false})}
                        className="react-modal user-modal"
                        overlayClassName="react-modal-overlay user-modal-overlay"
                    >
                        <UserModal logoutHandlerFunction={() => this.logoutHandler()}/>
                    </ReactModal>
                    <ReactModal
                        isOpen={this.state.showBookingConfirmationModal}
                        onRequestClose={() => this.setState({showBookingConfirmationModal: false})}
                        className="react-modal booking-confirmation-modal"
                        overlayClassName="react-modal-overlay booking-confirmation-modal-overlay"
                    >
                        <div className="booking-confirmation-modal-content">
                            <div className="booking-info">
                                <div className="info-row">
                                    <div className="title">{this.props.t('locker_size')}:</div>
                                    <div className="value">{this.state.selectedLockerSize.size}</div>
                                </div>
                                <div className="info-row">
                                    <div className="title booking-duration-title">{this.props.t('duration')}:</div>
                                    <div className="value booking-duration-value">
                                        <div>{`${this.state.selectedBookingDurationInHours > 1 ? `${this.state.selectedBookingDurationInHours} Hours` : `${this.state.selectedBookingDurationInHours} Hour`}`}</div>
                                        <div>{`(${moment(this.state.bookingDurationStartTimestamp).format("DD MMM, HH:mm")} - ${moment(this.state.bookingDurationEndTimestamp).format("DD MMM, HH:mm")})`}</div>
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className="title">{this.props.t('total_amount')}:</div>
                                    <div className="value">{this.state.totalAmount > 0 ? `${this.state.totalAmount} Rs.` : this.props.t('freeText')}</div>
                                </div>
                            </div>
                            <div className="buttons-container">
                                <div className="cancel-button" onClick={() => this.setState({showBookingConfirmationModal: false})}>{this.props.t('button_cancel')}</div>
                                <div className="book-button" onClick={() => this.makePrePaymentRegistration()}>{this.props.t('button_book')}</div>
                            </div>
                        </div>
                    </ReactModal>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(LockerSizePage);