import {Component} from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HTTPApi from "i18next-http-backend";
import {BrowserRouter} from "react-router-dom";
import {DEFAULT_LANGUAGE_ENGLISH} from "./assets/constants/LanguageOptions";
import {retrieveDetailsOfPreviouslySelectedLanguageFromLocalStorage, retrieveISOCodeOfPreviouslySelectedLanguageFromLocalStorage} from "./lib/Utils";
import RouterComponent from "./components/RouterComponent";

i18n.use(initReactI18next).use(HTTPApi).init({
  lng: retrieveISOCodeOfPreviouslySelectedLanguageFromLocalStorage(),
  fallbackLng: DEFAULT_LANGUAGE_ENGLISH.value,
  backend: {
    loadPath: "/assets/locales/{{lng}}/translation.json"
  },
  interpolation: {
    escapeValue: false
  }
});

class App extends Component {
  constructor (props) {
    super (props)

    global.selectedLanguage = retrieveDetailsOfPreviouslySelectedLanguageFromLocalStorage();
    this.handleBackButton = this.handleBackButton.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleBackButton(event) {
    const currentPage = window.location.pathname;
    if(currentPage === '/locker-size'){
      window.location.reload();
    }
  }

  render () { 
    return (
      <BrowserRouter>
        <RouterComponent/>
      </BrowserRouter>
    );
  }
}

export default App;
