import {Component} from "react";
import i18n from "i18next";
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {TOAST_INFO} from "../assets/constants/Constants";
import {LANGUAGE_OPTIONS} from "../assets/constants/LanguageOptions";
import ShowToast from "../components/ToastComponent";

class Footer extends Component {
    constructor (props) {
        super (props)
    }

    render () {
        return (
            <div className="footer-container">
                <div className="footer">
                    <div className="language-selector-dropdown-container">
                        <div className="title language-selector-dropdown-title">{this.props.t('select_language')}</div>
                        {/* <div className="language-selector-dropdown" onClick={() => ShowToast(`Currently only English langauge is available.`, TOAST_INFO)}> */}
                            <Select
                                className="react-select language-selector"
                                classNamePrefix="language-selector"
                                isSearchable={true}
                                menuPlacement="top"
                                isRtl={false}
                                isDisabled={false}
                                value={global.selectedLanguage}
                                onChange={(event) => {
                                    localStorage.setItem("selectedLanguage", JSON.stringify(event));
                                    global.selectedLanguage = event;
                                    i18n.changeLanguage(event.value);
                                }}
                                options={LANGUAGE_OPTIONS}
                            />
                        {/* </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Footer);