import {Component} from "react";
import moment from "moment";
import ReactModal from "react-modal";
import QrReader from "react-qr-reader";
import {LUGGAGE, ERROR, SUCCESS, REDIRECT, TOAST_SUCCESS, TOAST_WARN, TOAST_ERROR, OPEN_LOCKER} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_GUEST_USER, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS} from "../assets/constants/BrowserStorageKeys";
import {MdAccountCircle} from "react-icons/md";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {isGuestUserAuthenticated, getTenantAssignmentLockerBankConfigurationSettings} from "../lib/AuthUtils";
import {getDomainAndLockerBankInfoFromScannedQRCode} from "../lib/Utils";
import {releaseLocker} from "../lib/BackendUtils";
import {storeSecureItemInSpecifiedStorage, getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";
import UserModal from "../components/UserModal";
import {REMAINING_TIME_DONUT_CHART} from "../components/RemainingTimeDonutChart";
import Footer from "../components/Footer";

class LockerOpenedPage extends Component {
    constructor (props) {
        super (props)

        const tenantAssignmentAndLockerBankSettings = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS);
        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const domain = domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null;
        const lockerBankId = domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null;
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);
        const assignedLockerInfo = this.props.history.location.state?.assignedLockerInfo;
        const currentTimeInMilliseconds = moment().valueOf();
        const bookingStartTimeInMilliseconds = parseInt(assignedLockerInfo?.bookingStartTimeInMilliseconds);
        const bookingEndTimeInMilliseconds = parseInt(assignedLockerInfo?.bookingEndTimeInMilliseconds);
        const bookingEndTimeWithGracePeriodInMilliseconds = parseInt(assignedLockerInfo?.bookingEndTimeWithGracePeriodInMilliseconds);
        const showGracePeriodCountdownTimer = (currentTimeInMilliseconds < bookingEndTimeInMilliseconds) ? false : true;
        const remainingTimeInMilliseconds = showGracePeriodCountdownTimer ? (bookingEndTimeWithGracePeriodInMilliseconds - currentTimeInMilliseconds) : (bookingEndTimeInMilliseconds - currentTimeInMilliseconds);

        this.state = {
            isLoading: true,
            loadingMessage: null,
            showUserModal: false,
            showQRCodeReader: false,
            showReleaseLockerConfirmationModal: false,
            showReleaseLockerButton: (localStorage.getItem("userAction") === OPEN_LOCKER) ? true : false,
            showGracePeriodCountdownTimer: showGracePeriodCountdownTimer,
            stopCountdownTimerAndClearIntervalWhenTrue: remainingTimeInMilliseconds < 0 ? true : false,
            domain: domain,
            lockerBankId: lockerBankId,
            tenantAssignmentAndLockerBankSettings: tenantAssignmentAndLockerBankSettings,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            assignedLockerInfo: assignedLockerInfo,
            currentTimeInMilliseconds: currentTimeInMilliseconds,
            bookingStartTimeInMilliseconds: bookingStartTimeInMilliseconds,
            bookingEndTimeInMilliseconds: bookingEndTimeInMilliseconds,
            bookingEndTimeWithGracePeriodInMilliseconds: bookingEndTimeWithGracePeriodInMilliseconds,
            remainingTimeInMilliseconds: remainingTimeInMilliseconds < 0 ? 0 : remainingTimeInMilliseconds
        }
    }

    componentDidMount = () => {
        if (this.state.isUserAuthenticated) {
            this.setState({
                isLoading: false
            });
            this.countDownTimerForRemainingDuration();
        }
    }

    userModalHandler = () => {
        this.setState({
            showUserModal: true
        });
    }

    logoutHandler = () => {
        localStorage.removeItem(LS_GUEST_USER);
        sessionStorage.clear();

        this.setState({
            stopCountdownTimerAndClearIntervalWhenTrue: true
        }, () => {
            this.props.history.push({
                pathname: "/",
                state: {
                    message: this.props.t('success_msg_successfully_logged_out'),
                    messageType: TOAST_SUCCESS
                }
            });
        });
    }

    countDownTimerForRemainingDuration = () => {
        let currentTimeInMilliseconds = this.state.currentTimeInMilliseconds;
        let showGracePeriodCountdownTimer = this.state.showGracePeriodCountdownTimer;
        let remainingTimeInMilliseconds = this.state.remainingTimeInMilliseconds;

        let countDownTimerInterval = setInterval(() => {
            if (this.state.stopCountdownTimerAndClearIntervalWhenTrue) {
                clearInterval(countDownTimerInterval);
            }
            else {
                currentTimeInMilliseconds = moment().valueOf();
                showGracePeriodCountdownTimer = (currentTimeInMilliseconds < this.state.bookingEndTimeInMilliseconds) ? false : true;
                remainingTimeInMilliseconds = showGracePeriodCountdownTimer ? (this.state.bookingEndTimeWithGracePeriodInMilliseconds - currentTimeInMilliseconds) : this.state.bookingEndTimeInMilliseconds - currentTimeInMilliseconds;

                this.setState({
                    showGracePeriodCountdownTimer: showGracePeriodCountdownTimer,
                    stopCountdownTimerAndClearIntervalWhenTrue: remainingTimeInMilliseconds < 0 ? true : false,
                    remainingTimeInMilliseconds: remainingTimeInMilliseconds < 0 ? 0 : remainingTimeInMilliseconds
                });
            }
        }, 1000);
    }

    releaseOpenedLocker = async () => {
        this.setState({
            isLoading: true,
            loadingMessage: this.props.t('releasing_locker_please_wait', {lockerUnit: this.state.assignedLockerInfo?.lockerUnit}),
            showReleaseLockerConfirmationModal: false
        });

        const additionalHeaders = {Authorization: `token ${this.state.userInfo.authToken}`};
        const body = {locker_bank_id: String(this.state.assignedLockerInfo?.lockerBankId), locker_unit: this.state.assignedLockerInfo?.lockerUnit, type: LUGGAGE};
        const response = await releaseLocker(this.state.domain, additionalHeaders, body);

        if (response.status === SUCCESS) {
            let userInfo = this.state.userInfo;
            userInfo.assignedLockersCount = userInfo.assignedLockersCount - 1;
            storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_GUEST_USER, userInfo);

            this.setState({
                isLoading: false,
                stopCountdownTimerAndClearIntervalWhenTrue: true
            }, () => {
                this.props.history.push({
                    pathname: "/locker-released",
                    state: {
                        releasedLockerInfo: this.state.assignedLockerInfo
                    }
                });
            });
        }
        else if (response.status === ERROR) {
            this.setState({
                isLoading: false,
            }, () => {
                ShowToast(this.props.t(response.message), TOAST_ERROR);
            });
        }
        else if (response.status === REDIRECT) {
            this.setState({
                isLoading: false,
            }, () => {
                this.props.history.push({
                    pathname: "/",
                    state: {
                        message: this.props.t(response.message),
                        messageType: TOAST_ERROR
                    }
                });
            });
        }
    }

    myLockersButtonHandler = () => {
        localStorage.setItem("userAction", OPEN_LOCKER);

        this.setState({
            stopCountdownTimerAndClearIntervalWhenTrue: true
        }, () => {
            this.props.history.push({
                pathname: "/my-lockers"
            });
        });
    }

    bookAnotherLockerButtonHandler = () => {
        this.setState({
            isLoading: true,
            loadingMessage: this.props.t('verifying_locker_bank_settings_please_wait'),
            showQRCodeReader: true
        });
    }

    cancelQRCodeScanButtonHandler = () => {
        this.setState({
            isLoading: false,
            loadingMessage: "",
            showQRCodeReader: false
        });
    }

    qrReaderScanHandler = (qrCodeData) => {
        if (qrCodeData) {
            this.setState({
                showQRCodeReader: false
            }, async () => {
                const domainAndLockerBankInfo = getDomainAndLockerBankInfoFromScannedQRCode(qrCodeData);

                if (domainAndLockerBankInfo) {
                    if (domainAndLockerBankInfo.domain === this.state.domain) {
                        const configurationSettings = await getTenantAssignmentLockerBankConfigurationSettings(domainAndLockerBankInfo.domain, domainAndLockerBankInfo.lockerBankId);

                        if (configurationSettings.tenantAssignmentAndLockerBankSettings) {
                            if (configurationSettings.availableLockersCount > 0) {
                                if (domainAndLockerBankInfo.lockerBankId !== this.state.lockerBankId) {
                                    storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, domainAndLockerBankInfo);
                                }
                                storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, configurationSettings.tenantAssignmentAndLockerBankSettings);

                                this.setState({
                                    stopCountdownTimerAndClearIntervalWhenTrue: true
                                }, () => {
                                    ShowToast(this.props.t('success_msg_you_are_now_accessing_locker_bank', {lockerBankName: configurationSettings.tenantAssignmentAndLockerBankSettings.lockerBankName}), TOAST_SUCCESS);
                                    this.props.history.push({
                                        pathname: "/locker-size"
                                    });
                                })
                            }
                            else {
                                ShowToast(this.props.t('error_msg_locker_units_not_available_try_later'), TOAST_ERROR);
                            }
                        }
                        else {
                            ShowToast(this.props.t(configurationSettings.errorMessage), TOAST_ERROR);
                        }
                    }
                    else {
                        ShowToast(this.props.t('error_msg_locker_bank_is_under_different_tenant'), TOAST_ERROR);
                    }
                }
                else {
                    ShowToast(this.props.t('error_msg_scanned_qr_not_valid'), TOAST_ERROR);
                }

                this.setState({
                    isLoading: false,
                    loadingMessage: ""
                });
            });
        }
    }

    qrReaderErrorHandler = (error) => {
        this.setState({
            isLoading: false,
            loadingMessage: "",
            showQRCodeReader: false
        }, () => {
            ShowToast(this.props.t('error_msg_error_while_opening_qr_reader'), TOAST_ERROR);
        });
    }
    
    componentWillUnmount = () => {}

    render () {
        return (
            <div className="locker-opened-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">{this.props.t('page_title_locker_opened')}</div>
                        <div className="user" onClick={() => this.userModalHandler()}>
                            <MdAccountCircle className="icon-md-account-circle"/>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            {this.state.loadingMessage && (
                                <div className="loading-message">{this.state.loadingMessage}</div>
                            )}
                        </div>
                    ) : (
                        <div className="main">
                            <div className="locker-opened-info">{this.props.t('locker_unit_is_opened', {lockerUnit: this.state.assignedLockerInfo?.lockerUnit})}</div>
                            <div className="locker-usage-instructions">{this.props.t('close_the_locker_after_use')}</div>
                            <div className="locker-opened-info">{this.props.t('remaining_time')}</div>
                            <div className="remaining-time-donut-chart-container">{REMAINING_TIME_DONUT_CHART(this.state.remainingTimeInMilliseconds, this.state.bookingEndTimeInMilliseconds - this.state.bookingStartTimeInMilliseconds)}</div>
                            {this.state.showReleaseLockerButton && (
                                <div className="release-locker-button" onClick={() => this.setState({showReleaseLockerConfirmationModal: true})}>{this.props.t('button_release_locker')}</div>
                            )}
                            <div className="my-lockers-button" onClick={() => this.myLockersButtonHandler()}>{this.props.t('button_my_lockers')}</div>
                            {this.state.userInfo.assignedLockersCount < this.state.tenantAssignmentAndLockerBankSettings.allowedLockersCount ? (
                                <div className="book-another-locker-button" onClick={() => this.bookAnotherLockerButtonHandler()}>{this.props.t('button_book_new_locker')}</div>
                            ) : (
                                <div className="book-another-locker-button disabled-button" onClick={() => ShowToast(this.props.t('warning_msg_max_lockers_booked_release_before_booking_new_one'), TOAST_WARN)}>{this.props.t('button_book_new_locker')}</div>
                            )}
                        </div>
                    )}
                </div>
                <ReactModal
                    isOpen={this.state.showUserModal}
                    onRequestClose={() => this.setState({showUserModal: false})}
                    className="react-modal user-modal"
                    overlayClassName="react-modal-overlay user-modal-overlay"
                >
                    <UserModal logoutHandlerFunction={() => this.logoutHandler()}/>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showQRCodeReader}
                    onRequestClose={() => {}}
                    className="react-modal qr-code-reader-modal"
                    overlayClassName="react-modal-overlay qr-code-reader-modal-overlay"
                >
                    <div className="qr-code-reader-container">
                        <QrReader
                            className="qr-code-reader"
                            delay={300}
                            onScan={(data) => this.qrReaderScanHandler(data)}
                            onError={(error) => this.qrReaderErrorHandler(error)}
                        />
                        <div className="button-container">
                            <div className="cancel-button" onClick={() => this.cancelQRCodeScanButtonHandler()}>{this.props.t('button_cancel')}</div>
                        </div>
                    </div>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showReleaseLockerConfirmationModal}
                    onRequestClose={() => this.setState({showReleaseLockerConfirmationModal: false})}
                    className="react-modal release-locker-confirmation-modal"
                    overlayClassName="react-modal-overlay release-locker-confirmation-modal-overlay"
                >
                    <div className="release-locker-confirmation-modal-content">
                        <div className="confirmation-message">{this.props.t('are_you_sure_to_release_the_locker')}</div>
                        <div className="info-message">{this.props.t('check_belongings_before_releasing_locker')}</div>
                        <div className="buttons-container">
                            <div className="cancel-button" onClick={() => this.setState({showReleaseLockerConfirmationModal: false})}>{this.props.t('button_cancel')}</div>
                            <div className="release-button" onClick={() => this.releaseOpenedLocker()}>{this.props.t('button_release')}</div>
                        </div>
                    </div>
                </ReactModal>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(LockerOpenedPage);