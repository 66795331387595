import {Component} from "react";
import {withTranslation} from "react-i18next";
import {TOAST_ERROR} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK} from "../assets/constants/BrowserStorageKeys";
import {storeSecureItemInSpecifiedStorage} from "../lib/BrowserStorageAccessMiddleware";

class LandingPage extends Component {
    constructor (props) {
        super (props)

        const domainAndLockerBankInfoInBase64 = this.props.match.params.domainAndLockerBankInfoInBase64;
        let domainAndLockerBankInformation = null;
        let isDomainValid = null;
        let isLockerBankIdValid = null;

        try {
            domainAndLockerBankInformation = window.atob(domainAndLockerBankInfoInBase64);
            isDomainValid = /^{.*(?="domain"\s*:\s*"[^"]{1,}").*}$/.test(domainAndLockerBankInformation);
            isLockerBankIdValid = /^{.*(?="lockerBankId"\s*:\s*"\d+").*}$/.test(domainAndLockerBankInformation);
        }
        catch (error) {
            console.log('Error while scanning the QR code: ', error);
        }

        if (isDomainValid && isLockerBankIdValid) {
            const domainAndLockerBankInfoObject = JSON.parse(domainAndLockerBankInformation);
            storeSecureItemInSpecifiedStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, domainAndLockerBankInfoObject);
        }
        else {
            localStorage.clear();
        }

        this.props.history.push({
            pathname: "/",
            state: {
                message: (isDomainValid && isLockerBankIdValid) ? null : this.props.t('error_msg_scanned_qr_not_valid'),
                messageType: TOAST_ERROR
            }
        });
    }

    render () {
        return (
            <></>
        );
    }
}

export default withTranslation()(LandingPage);