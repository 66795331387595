export const PWA_DOMAIN_DEV = "https://pwadev.durolt.in";
export const PWA_DOMAIN_PROD = "https://luggage.durolt.com";
export const API = "api";
export const PWA = "pwa";
export const LUGGAGE = "luggage";
export const LUGGAGE_SETTINGS = "luggage-settings";
export const SEND_OTP = "send-otp";
export const VERIFY_OTP = "verify-otp";
export const AVAILABLE_SIZES = "available-sizes";
export const INITIATE_PAYMENT = "initiate-payment";
export const BOOKING_TYPE_NEW = "new";
export const BOOKING_TYPE_ADDITIONAL = "additional";
export const POST_PAYMENT = "post-payment";
export const LOCKER_BANK = "locker-bank";
export const ASSIGNED_LOCKERS = "assigned-lockers";
export const OPEN_LOCKER = "open-locker";
export const RELEASE_LOCKER = "release-locker";
export const GET = "GET";
export const POST = "POST";
export const SUCCESS = "success";
export const ERROR = "error";
export const REDIRECT = "redirect";
export const GUEST_AUTH_TOKEN = 'GUEST-AUTH-TOKEN';
export const GUEST_AUTH_TOKEN_VALUE = "#up!7O@rh#55Fp3I1CpHQ!d5xs2J2c#FRc@x^bb@ZHTzK5@v5bCivAjSjAx^3Vhs";
export const HH_mm = "HH:mm";
export const HH_mm_ss = "HH:mm:ss";

// OTPless authentication
export const OTPLESS_AUTHENTICATION_SOURCE = "https://otpless.com/auth.js";
export const OTPLESS_DUROLT_CID = "VCA1GURCQ7JRRAGY70FRFX9ZBXUJHDFV";

export const EASEBUZZ_PAYMENT_GATEWAY_ENV = "prod";
export const EASEBUZZ_PAYMENT_GATEWAY_SOURCE = "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js";
export const EASEBUZZ_DUROLT_MERCHANT_KEY = "ZJSW49MVY3";
//Toast Message Types
export const TOAST_INFO = "INFO";
export const TOAST_WARN = "WARN";
export const TOAST_SUCCESS = "SUCCESS";
export const TOAST_ERROR = "ERROR";

// Regular expressions
export const REGEXP_EMAIL = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
// export const REGEXP_MOBILE = /^(\+91[\s]?)[6789]\d{9}$/; //currently validating only indian numbers.
export const REGEXP_MOBILE = /^[6789]\d{9}$/;
export const REGEXP_OTP = /[0-9]{4}/i;

// User actions
// export const OPEN_LOCKER = "openLocker";
export const BOOK_NEW_LOCKER = "bookNewLocker";