import {Component} from "react";
import moment from "moment";
import ReactModal from "react-modal";
import SlideButton from "react-slide-button";
import {SUCCESS, ERROR, REDIRECT, TOAST_ERROR, BOOKING_TYPE_NEW, TOAST_SUCCESS} from "../assets/constants/Constants";
import {LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_GUEST_USER} from "../assets/constants/BrowserStorageKeys";
import {GiLockers} from "react-icons/gi";
import {IoTime} from "react-icons/io5";
import {MdAccountCircle, MdCheckCircle, MdLocationOn} from "react-icons/md";
import {RiDoorLockBoxFill} from "react-icons/ri";
import {ROTATING_LOADING_ICON, CHEVRONS_RIGHT} from "../assets/constants/Icons";
import {isGuestUserAuthenticated} from "../lib/AuthUtils";
import {openLocker} from "../lib/BackendUtils";
import {getSecureItemFromSpecificStorage} from "../lib/BrowserStorageAccessMiddleware";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";
import UserModal from "../components/UserModal";
import Footer from "../components/Footer";

class PaymentCompletionPage extends Component {
    constructor (props) {
        super (props)

        const domainAndLockerBankInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK);
        const domain = domainAndLockerBankInfo?.domain ? domainAndLockerBankInfo?.domain : null;
        const lockerBankId = domainAndLockerBankInfo?.lockerBankId ? domainAndLockerBankInfo?.lockerBankId : null;
        const userInfo = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_GUEST_USER);
        const isUserAuthenticated = isGuestUserAuthenticated(userInfo);
        const transactionDetails = this.props.history.location.state?.transactionDetails;
        const assignedLockerInfo = this.props.history.location.state?.assignedLockerInfo;

        this.state = {
            isLoading: true,
            loadingMessage: null,
            isOpeningLockerUnit: false,
            showUserModal: false,
            domain: domain,
            lockerBankId: lockerBankId,
            userInfo: userInfo,
            isUserAuthenticated: isUserAuthenticated,
            transactionDetails: transactionDetails,
            assignedLockerInfo: assignedLockerInfo,
        }
    }

    componentDidMount = () => {
        if (this.state.isUserAuthenticated) {
            this.setState({
                isLoading: false
            });
        }
    }

    userModalHandler = () => {
        this.setState({
            showUserModal: true
        });
    }

    logoutHandler = () => {
        localStorage.removeItem(LS_GUEST_USER);
        sessionStorage.clear();

        this.props.history.push({
            pathname: "/",
            state: {
                message: this.props.t('success_msg_successfully_logged_out'),
                messageType: TOAST_SUCCESS
            }
        });
    }

    openLockerButtonHandler = async () => {
        this.setState({
            isOpeningLockerUnit: true
        });

        const additionalHeaders = {Authorization: `token ${this.state.userInfo.authToken}`};
        const body = {locker_bank: this.state.assignedLockerInfo.locker_bank_id, locker_unit: this.state.assignedLockerInfo.locker_unit};
        const response = await openLocker(this.state.domain, additionalHeaders, body);

        if (response.status === SUCCESS) {
            const assignedLockerInfo = {
                lockerBankId: response.data.data.locker_bank,
                lockerBankName: response.data.data.bank_name,
                lockerBankLocation: response.data.data.locker_bank_location,
                lockerUnit: response.data.data.locker_unit.unit_id,
                lockerSize: response.data.data.locker_unit.size,
                bookingStartTimeInMilliseconds: response.data.data.booking_start_time,
                bookingEndTimeInMilliseconds: response.data.data.booking_end_time,
                bookingEndTimeWithGracePeriodInMilliseconds: response.data.data.booking_end_time_grace
            };
            
            this.setState({
                isOpeningLockerUnit: false
            }, () => {
                this.props.history.push({
                    pathname: "/locker-opened",
                    state: {
                        assignedLockerInfo: assignedLockerInfo
                    }
                });
            });
        }
        else if (response.status === ERROR) {
            this.setState({
                isOpeningLockerUnit: false
            }, () => {
                ShowToast(this.props.t(response.message), TOAST_ERROR);
            });
        }
        else if (response.status === REDIRECT) {
            this.setState({
                isOpeningLockerUnit: false
            }, () => {
                this.props.history.push({
                    pathname: "/",
                    state: {
                        message: this.props.t(response.message),
                        messageType: TOAST_ERROR
                    }
                });
            });
        }
    }

    componentWillUnmount = () => {}

    render () {
        return (
            <div className="payment-completion-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">{this.props.t('page_title_payment_successful')}</div>
                        <div className="user" onClick={() => this.userModalHandler()}>
                            <MdAccountCircle className="icon-md-account-circle"/>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            {this.state.loadingMessage && (
                                <div className="loading-message">{this.state.loadingMessage}</div>
                            )}
                        </div>
                    ) : (
                        <div className="main">
                            <div className="booking-info-container">
                                <div className="booking-status-container">
                                    <div className="icon-container">
                                        <MdCheckCircle className="icon-md-check-circle" />
                                    </div>
                                    <div className="booking-status-message">{this.props.t('payment_successful')}</div>
                                </div>
                                <div className="booking-info">
                                    <div className="info-row">
                                        <div className="icon-container">
                                            <RiDoorLockBoxFill className="booking-info-icon icon-ri-door-lock-box-fill" />
                                        </div>
                                        <div className="title-and-value-container">
                                            <div className="title">{this.props.t('locker')}</div>
                                            <div className="value">{this.state.assignedLockerInfo.locker_unit}</div>
                                        </div>
                                        <div className="locker-size">{this.state.assignedLockerInfo.locker_size}</div>
                                    </div>
                                    <div className="info-row">
                                        <div className="icon-container">
                                            <GiLockers className="booking-info-icon icon-gi-lockers" />
                                        </div>
                                        <div className="title-and-value-container">
                                            <div className="title">{this.props.t('locker_bank_name')}</div>
                                            <div className="value">{this.state.assignedLockerInfo.locker_bank_name}</div>
                                        </div>
                                    </div>
                                    <div className="info-row">
                                        <div className="icon-container">
                                            <MdLocationOn className="booking-info-icon icon-md-location" />
                                        </div>
                                        <div className="title-and-value-container">
                                            <div className="title">{this.props.t('locker_bank_location')}</div>
                                            <div className="value">{this.state.assignedLockerInfo.locker_bank_location}</div>
                                        </div>
                                    </div>
                                    <div className="info-row">
                                        <div className="icon-container">
                                            <IoTime className="booking-info-icon icon-md-location" />
                                        </div>
                                        <div className="title-and-value-container">
                                            <div className="title">{this.props.t('duration')}</div>
                                            <div className="value">{`${moment(this.state.assignedLockerInfo.booking_start_time).format("DD MMM, hh:mm A")} - ${moment(this.state.assignedLockerInfo.booking_end_time).format("DD MMM, hh:mm A")}`}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slide-button-container">
                                {this.state.isOpeningLockerUnit ? (
                                    <div className="loading-icon-and-message-container">
                                        <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                                        <div className="loading-message">{this.props.t('opening_locker_please_wait')}</div>
                                    </div>
                                ) : (
                                    <SlideButton
                                        mainText="Slide to open locker"
                                        overlayText={this.props.t('button_open_locker')}
                                        caret={CHEVRONS_RIGHT()}
                                        classList="slide-button"
                                        overlayClassList="slide-button-overlay"
                                        caretClassList="slide-button-caret"
                                        minSlideWidth={0.8}
                                        minSlideVelocity={1}
                                        onSlideDone={() => this.openLockerButtonHandler()}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <ReactModal
                    isOpen={this.state.showUserModal}
                    onRequestClose={() => this.setState({showUserModal: false})}
                    className="react-modal user-modal"
                    overlayClassName="react-modal-overlay user-modal-overlay"
                >
                    <UserModal logoutHandlerFunction={() => this.logoutHandler()}/>
                </ReactModal>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(PaymentCompletionPage);